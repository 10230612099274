#navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem;
    position: fixed;
    background-color: white;
    width: 100%;
    top: 0;
    z-index: 1;
}

#logo{
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    text-decoration: none;
    color: black;
}
#logo:hover{
    cursor: pointer;
    color: lightpink;
}

#nav-options > *{
    font-size: 2rem;
    padding: 0 2rem;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: black;
    border-radius: 0.25rem;
}

#nav-options > *:hover {
    background-color: #72C2C7;
    border-bottom: 0.25rem solid black;
    transition: background-color, border 0.5s;
}

@media only screen and (max-width: 840px) {
    #nav-options > *{
        font-size: 1rem;
        padding: 0 0.7rem;
    }
}

@media only screen and (max-width: 480px) {
    #nav-options > *{
        font-size: 1rem;
        padding: 0 0.5rem;
    }
    #logo{ 
        font-size: 1.5rem;
    }
}
  
  