.project {
    border-radius: 0.5rem;
    margin: 1rem;
    padding: 1rem;
}
.project:hover{
    cursor: pointer;
    background-color: rgb(251, 251, 148);
    border-left: 0.25rem solid black;
    border-bottom: 0.25rem solid black;
    transition: border 0.3s;
}

.project-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
}

.project-desc {

}

@media only screen and (max-width: 840px) {
    .project-name {
        font-size: 1.25rem;
    }
    .project-desc {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 480px) {
    .project-name {
        font-size: 1rem;
    }
    .project-desc {
        font-size: 0.8rem;
    }
}