#projects-container {
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
}

#projects-container-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    text-shadow: 0px 4px #FAE470;
}

#projects-list-container {
    width: 100%;
    border: 0.75rem solid pink;
}