#education-container{
    background-color: #FAE470;
    width: 70%;
    margin: 3rem auto;
}

#education-container-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    text-shadow: 0px 4px pink;
    position: relative;
    right: 1rem;
    bottom: 2rem;
}

.education {
    width: 100%;
    padding-bottom: 4rem;
}
.education > * {
    font-family: 'Montserrat', sans-serif;
}

.education-topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}
.education-name {
    font-size: 2.2rem;
    font-weight: 600;
}
.education-subheading {
    padding: 0 2rem;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    font-weight: 600;
    font-style: italic;
}
.education-description {
    padding: 0 2rem;
}
.education-description > * {
    margin: 0.3rem 0;
}

.education-description-field {
    font-weight: bold;
}

@media only screen and (max-width: 840px) {
    .education-name {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 480px) {
    .education-name {
        font-size: 1.5rem;
    }
}