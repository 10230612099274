body {
    width: 100%;
}

#description {
    margin-top: 6.4rem;
}

#description-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
#description-title-container > * {
    padding: 0.2rem 0;
}

#description-title {
    font-size: 1.5rem;
}

#description-gray-background-container {
    display: flex;
    justify-content: space-around;
}

.horizontal-line {
    width: 40rem;
    border-bottom: 1px solid black;
}
.gray-background {
    background-color: rgb(234, 234, 234);
    padding: 2rem;
    margin: 1rem;
    border-radius: 1rem;
    width: 40%;
}
.heading-d1 {
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: 1px solid black;
    width: 30%;
}

#description-imgs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.description-photo {
    width: 80%;
    margin: 2rem auto;
}


.link-to-product {
    text-decoration: none;
    font-weight: bold;
    color: black;
    font-size: 1.5rem;
    margin-top: 1rem;
    padding: 0.2rem;
    border-radius: 5px;
}
.link-to-product:hover {
    background-color: #72C2C7;
    border-left: 0.25rem solid black;
    border-bottom: 0.25rem solid black;
    transition: background-color, border 0.5s;
}

@media only screen and (max-width: 840px) {
    .gray-background {
        padding: 1.75rem;
    }
    .heading-d1 {
    }
    .description-main-text {
        font-size: 1rem;
    }
    .link-to-product {
    }
}

@media only screen and (max-width: 480px) {
    .gray-background {
        padding: 1.5rem;
    }
    .heading-d1 {
        font-size: 1.25rem;
    }
    .description-main-text {
        font-size: 1rem;
    }
    .link-to-product {
        font-size: 1.25rem;
    }
}