#experience-container {
    width: 100%;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#experience-container-title {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    text-shadow: 0px 4px #72C2C7;
}

#exp-toggle{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    /*width: 20%;*/
}

#experience-toggle-title {
    font-size: 2rem;
    margin: 1rem 0;
}


/* ----------- toggle css ------------ */
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 2rem;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: pink;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: black;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px black;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/* ---------------------- */