.experience {
    background-color: #72C2C7;
    width: 60%;
    margin: 2rem auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
}
.experience:hover {
    cursor: pointer;
    border-left: 0.60rem solid black;
    border-bottom: 0.60rem solid black;
    transition: border 0.3s;
}

.experience-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.experience-text > * {
    font-family: 'Montserrat', sans-serif;
    padding: 0.5rem 1rem;
}

.experience-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0 auto;
}

.experience-img {
    border-radius: 50%;
    height: 8rem;
    margin: 0.25rem;
}

@media only screen and (max-width: 840px) {
    .experience-img {
        height: 6rem;
    }
    .experience-title {
        font-size: 1.25rem;
    }
    .experience-description {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 480px) {
    .experience-img {
        height: 4rem;
    }
    .experience-title {
        font-size: 1rem;
    }
    .experience-description {
        font-size: 0.8rem;
    }
}