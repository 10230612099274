#footer {
    display: flex;
    justify-content: center;
}

#footer > * > a {
    text-decoration: none;
    color: black;
}

#footer > * {
    background-color: #72C2C7;
    padding: 1rem;
    margin: 1rem;
    border-radius: 50%;
}
#footer > *:hover{
    cursor: pointer;
    border-left: 0.3rem solid black;
    border-bottom: 0.1rem solid black;
    transition: border 0.3s;
}

.footer-logo-container {
    margin: auto;
    
}

.material-symbols-outlined {
    font-size: 3rem !important;
}
.footer-img {
    width: 3rem;
    background-color: #72C2C7;
}
.footer-git-img {
    width: 3rem;
}

@media only screen and (max-width: 840px) {
    #footer > * {
        padding: 0.75rem;
        margin: 1rem;
    }
    .material-symbols-outlined {
        font-size: 2.75rem !important;
    }
    .footer-img {
        width: 2.75rem;
    }
    .footer-git-img {
        width: 2.75rem;
    }
}

@media only screen and (max-width: 480px) {
    #footer > * {
        padding: 0.5rem;
        margin: 1rem;
    }
    .material-symbols-outlined {
        font-size: 2.5rem !important;
    }
    .footer-img {
        width: 2.5rem;
    }
    .footer-git-img {
        width: 2.5rem;
    }
}